import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Button/Button.tsx';
import { Footer } from '../../../components/Footer/Footer.tsx';
import { Header } from '../../../components/Header/Header.tsx';
import '../FormPage.scss';
import { useSignUpMutation } from './signUpPage.gen.ts';
import { signupSchema } from './signUpSchema.ts';

const BACKEND_ERRORS = {
    EMAIL_EXISTS: 'Nie udało się założyć konta. Upewnij się, że podane dane są prawidłowe i że ten adres e-mail nie został już wcześniej zarejestrowany.',
    INCORRECT_EMAIL: 'Nie udało się założyć konta. Upewnij się, że podane dane są prawidłowe i że ten adres e-mail nie został już wcześniej zarejestrowany.',
    INCORRECT_PASSWORD: 'Hasło jest za krótkie',
    INCORRECT_USERNAME: 'Nie udało się założyć konta. Upewnij się, że podane dane są prawidłowe i że ten adres e-mail nie został już wcześniej zarejestrowany.',
    USERNAME_EXISTS: 'Nie udało się założyć konta. Upewnij się, że podane dane są prawidłowe i że ten adres e-mail nie został już wcześniej zarejestrowany.',
};

const SignUpPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [signUpMutation, { data }] = useSignUpMutation();

    const handleUnregistered = () => {
        sessionStorage.setItem('unregisteredUser', JSON.stringify(true));
        navigate('/licytacja');
    };

    return (
        <div className="formPage formPage--signup">
            <Header />
            <div className="formPage__content">
                <p className="formPage__heading">
                    <span className="formPage__heading--licytapka">Licytapka</span> to Twoja partnerka w nauce licytacji brydżowych
                </p>
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                        username: '',
                        terms: false,
                        marketingConsent: false,
                    }}
                    validationSchema={signupSchema}
                    onSubmit={async (values) => {
                        console.log(values);
                        await signUpMutation({
                            variables: {
                                input: {
                                    email: values.email,
                                    password: values.password,
                                    username: values.username,
                                    marketingConsent: values.marketingConsent,
                                },
                            },
                        }).then((res) => {
                            if (res.data?.signUp?.__typename === 'SignUpSuccessView') {
                                navigate('/check-email');
                            }
                        });
                    }}
                >
                    {(formik) => {
                        const { errors, touched, isValid, dirty } = formik;

                        return (
                            <div className="formPage__formContainer">
                                <div className="formPage__col formPage__col--right">
                                    <h2 className="formPage__title">Masz już konto?</h2>
                                    <Button customClass="formPage__button" variant="ghost-white" onClick={() => navigate('/login')} text="Zaloguj się" />
                                    <p className="formPage__noAccount">
                                        Nie chcesz korzystać z fajnych funkcjonalności jak Statystyki i Grupy?{' '}
                                        <Button
                                            customClass="formPage__link"
                                            variant="text"
                                            onClick={() => {
                                                handleUnregistered();
                                            }}
                                        >
                                            Korzystaj z Licytapki bez zakładania konta.
                                        </Button>
                                    </p>
                                </div>
                                <div className="formPage__col formPage__col--left">
                                    <h2 className="formPage__title">Załóż konto</h2>
                                    <Form>
                                        <div className="formPage__formGroup form-row">
                                            <Field maxLength={20} type="username" placeholder="Nick*" name="username" id="username" className={`formPage__formControl ${errors.username && touched.username ? 'input-error' : ''}`} />
                                            <ErrorMessage name="username" component="span" className="error" />
                                        </div>
                                        <div className="formPage__formGroup form-row">
                                            <Field type="email" placeholder="Email*" name="email" id="email" className={`formPage__formControl ${errors.email && touched.email ? 'input-error' : ''}`} />
                                            <ErrorMessage name="email" component="span" className="error" />
                                        </div>
                                        <div className="formPage__formGroup form-row">
                                            <Field placeholder="Hasło*" type="password" name="password" id="password" className={`formPage__formControl ${errors.password && touched.password ? 'input-error' : ''}`} />
                                            <ErrorMessage name="password" component="span" className="error" />
                                        </div>
                                        <div className="formPage__formGroup form-row">
                                            <label className="formPage__formCheckbox">
                                                <Field type="checkbox" name="terms" id="terms" required />
                                                <p>
                                                    Akceptuję <a href="/regulamin">Regulamin</a>
                                                </p>
                                            </label>
                                            <ErrorMessage name="terms" component="span" className="error" />
                                        </div>
                                        <div className="formPage__formGroup form-row form-row--noMt">
                                            <label className="formPage__formCheckbox">
                                                <Field type="checkbox" name="marketingConsent" id="marketingConsent" />
                                                <p>
                                                    Chcę otrzymywać <a href="#">informacje marketingowe</a>
                                                </p>
                                            </label>
                                            <ErrorMessage name="marketingConsent" component="span" className="error" />
                                        </div>
                                        <button type="submit" className={`button formPage__button${!(dirty && isValid) ? ' disabled' : ''}`} disabled={!(dirty && isValid)}>
                                            Załóż konto
                                        </button>
                                    </Form>

                                    {data?.signUp?.__typename === 'SignUpErrorView' && <p className="formPage__error error">{BACKEND_ERRORS[data?.signUp?.reason]}</p>}
                                </div>
                            </div>
                        );
                    }}
                </Formik>
            </div>
            <Footer />
        </div>
    );
};
export default SignUpPage;
