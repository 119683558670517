import { FormControl, FormControlLabel, InputLabel, MenuItem, Modal, Paper, Select, SelectChangeEvent, Switch, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Help } from '../../../assets/Help.tsx';
import { RoundClose } from '../../../assets/RoundClose.tsx';
import { Save } from '../../../assets/Save.tsx';
import { BiddingUI } from '../../../components/BiddingUI/BiddingUI.tsx';
import { IconButton } from '../../../components/IconButton/IconButton.tsx';
import { BiddingView, CardColorView, ExamDifficultyLevelView, ExamView, ExamVulnerabilityView } from '../../../graphql/types.gen.ts';
import { useLastGroupQuery } from '../../FormPage/LoginPage/loginPage.gen.ts';
import { ExamsFromGroupDocument } from '../../Game/game.gen.ts';
import { GetLessonsHomeworkBySlugDocument } from '../../Lessons/lessons.gen.ts';
import { useExamCreateMutation, useExamUpdateMutation, useGroupTagsQuery } from '../backoffice.gen.ts';
import { useGetBackofficeBidsArray } from '../helpers/useGetBackofficeBidsArray.ts';
import { vulnerabilityToString } from '../helpers/vulnerabilityToString.ts';
import { BackofficeBidSequence } from './BackofficeBidSequence/BackofficeBidSequence.tsx';
import { CardValueView, SmallCardsInput } from './SmallCardsInput/SmallCardsInput.tsx';

interface IBackofficeTestEditMode {
    test: ExamView;
    handleEditClick: (id: string | null) => void;
    handleLeaveEditClick: (id: string | null) => void;
    onSave: (id?: string) => void;
    index: number;
    isForLesson?: boolean;
}
const modalStyle = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
};

export interface IBidReference {
    index: number;
    innerIndex: number;
    type: 'bids' | 'answers' | 'halfAnswers';
}
export const BackofficeTestEditMode = (props: IBackofficeTestEditMode) => {
    const { test, handleEditClick, onSave, handleLeaveEditClick, index, isForLesson } = props;
    const [cards, setCards] = useState(test.definition.cards);
    const isInCards = (color: CardColorView, value: CardValueView) => {
        return !!cards.filter((item) => item.color.toUpperCase() === color.toUpperCase() && item.value === value).length;
    };
    const toggleCard = (color: CardColorView, value: CardValueView) => {
        if (isInCards(color, value)) {
            setCards(cards.filter((item) => item.color.toUpperCase() !== color.toUpperCase() || item.value !== value));
        } else {
            if (cards.length === 13) return;
            setCards((prevCards) => [...prevCards, { color: color.toUpperCase() as CardColorView, value: value.toUpperCase() as CardValueView }]);
        }
    };
    const { arr, updateArr, resetArr, answersToSave, validateExamObject } = useGetBackofficeBidsArray(test);
    const { data: chosenGroup } = useLastGroupQuery();
    const groupCode = chosenGroup?.me?.recentlyUsedGroupId || 'general';
    const [tag, setTag] = useState<string>(test.tags[0]);
    const [newtag, setNewTag] = useState(false);
    const [level, setLevel] = useState<ExamDifficultyLevelView>(test.difficultyLevel);
    const [vulnerability, setVulnerability] = useState<ExamVulnerabilityView>(test.vulnerability);
    const [examUpdateMutation, { loading: examUpdateMutationLoading }] = useExamUpdateMutation({ refetchQueries: [GetLessonsHomeworkBySlugDocument, ExamsFromGroupDocument] });
    const [examCreateMutation, { data: createMutationData }] = useExamCreateMutation({ refetchQueries: [GetLessonsHomeworkBySlugDocument, ExamsFromGroupDocument] });
    const [bidReference, setBidReference] = useState<IBidReference | null>(null);
    const [helpModalOpen, setHelpModalOpen] = useState(false);
    const [backofficeError, setbackofficeError] = useState(false);
    const [comment, setComment] = useState(test.comment || '');

    useEffect(() => {
        setbackofficeError(!validateExamObject(arr).validate);
    }, [arr, validateExamObject]);

    const { data, loading, error } = useGroupTagsQuery({
        variables: {
            code: groupCode,
        },
    });
    const levels = Object.values(ExamDifficultyLevelView);
    const vulnerabilities = Object.values(ExamVulnerabilityView);

    const handleChange = (event: SelectChangeEvent<string>) => {
        const {
            target: { value },
        } = event;
        setTag(value);
    };
    const handleTextTagChange = (event) => {
        const {
            target: { value },
        } = event;
        setTag(value);
    };
    const handleNewTagChange = () => {
        setNewTag((prev) => !prev);
        setTag('');
    };

    const handleUserBids = (b: BiddingView | null) => {
        if (!bidReference) return;
        updateArr(b, bidReference);
        if (!b) return;

        if (bidReference.type !== 'bids') {
            setBidReference((prev) => {
                return { type: 'bids', index: prev.index + 1, innerIndex: 0 };
            });
            return;
        }

        if (bidReference.innerIndex === 2) {
            setBidReference((prev) => {
                return { type: 'answers', index: prev.index, innerIndex: 0 };
            });
            return;
        }

        setBidReference((prev) => {
            return { type: 'bids', index: prev.index, innerIndex: prev?.innerIndex + 1 };
        });
    };

    const handleVulnerabilityChange = (event: SelectChangeEvent<ExamVulnerabilityView>) => {
        const {
            target: { value },
        } = event;
        setVulnerability(value as ExamVulnerabilityView);
    };

    const handleCommentChange = (event: SelectChangeEvent<string>) => {
        const {
            target: { value },
        } = event;
        setComment(value);
    };

    const handleLevelChange = (event: SelectChangeEvent<ExamDifficultyLevelView>) => {
        const {
            target: { value },
        } = event;
        setLevel(value as ExamDifficultyLevelView);
    };

    const saveTest = async () => {
        const filteredCards = cards.map(({ __typename, ...rest }) => rest);

        await examUpdateMutation({
            variables: {
                input: {
                    definition: {
                        cards: filteredCards,
                        initialBiddingSequence: answersToSave.initialBiddingSequence.map((item) => (item ? { ...item } : null)).filter((item) => !!item),
                        answers: answersToSave.answers,
                    },
                    examId: test.id,
                    difficultyLevel: isForLesson ? ExamDifficultyLevelView.Easy : level,
                    groupCode: groupCode,
                    name: test.name,
                    tags: isForLesson ? test.tags : [tag],
                    vulnerability: vulnerability,
                    comment: comment,
                },
            },
        }).then((r) => {
            if (r?.data?.examUpdate?.__typename === 'UpdateExamSuccessView') {
                onSave(r.data?.examUpdate?.examId);
            } else {
                toast.error(`Nie udało się zaktualizować testu ${r?.data?.examUpdate?.reason}`);
            }
        });
    };
    const createTest = () => {
        const filteredCards = cards.map(({ __typename, ...rest }) => rest);
        examCreateMutation({
            variables: {
                input: {
                    definition: {
                        cards: filteredCards,
                        initialBiddingSequence: answersToSave.initialBiddingSequence.map((item) => (item ? { ...item } : null)).filter((item) => !!item),
                        answers: answersToSave.answers,
                    },
                    difficultyLevel: isForLesson ? ExamDifficultyLevelView.Easy : level,
                    groupCode: groupCode,
                    name: test.name || new Date().toISOString().slice(2, 17).replace(/[-T:]/g, ''),
                    tags: isForLesson ? test.tags : [tag],
                    vulnerability: vulnerability,
                    comment: comment,
                },
            },
        }).then((r) => {
            if (r?.data?.examCreate?.__typename === 'CreateExamSuccessView') {
                onSave(r.data?.examCreate?.examId);
            } else {
                toast.error(`Nie udało się zaktualizować testu ${r?.data?.examCreate?.reason}`);
            }
        });
    };
    const notFilled = isForLesson ? !vulnerability || cards.length !== 13 || backofficeError : !tag || !level || !vulnerability || cards.length !== 13 || backofficeError;

    const handleModalOpen = () => {
        setHelpModalOpen(true);
    };

    const handleModalClose = () => {
        setHelpModalOpen(false);
    };

    return (
        <div className="backoffice__test backoffice__test--edit">
            <p className="backoffice__test-number">{index || 1}.</p>
            <div className="backoffice__test-wrapper">
                <div className="backoffice__test-content">
                    <div className="backoffice__cards">
                        <p className="backoffice__heading">Karty gracza S</p>
                        <SmallCardsInput cards={cards} toggleCard={toggleCard} isInCards={isInCards} />
                    </div>
                    <div className="backoffice__bids">
                        <div className="backoffice__seq">
                            <BackofficeBidSequence editMode={true} arr={arr} vulnerability={vulnerability || test.vulnerability} bidReference={bidReference} setBidReference={setBidReference} />
                            {backofficeError && <p className="backoffice__error">Sekwencja nieprawdiłowa</p>}
                        </div>
                        <BiddingUI setIsFocused={setBidReference} backofficeMode={true} handleUserBids={handleUserBids} isFocused={bidReference} />
                    </div>
                </div>
                <div className="backoffice__edit-tags">
                    {!isForLesson && data?.group?.possibleExamTags.length >= 1 && <FormControlLabel control={<Switch />} label="Dodaj nowy tag" onChange={handleNewTagChange} />}
                    {!isForLesson &&
                        (newtag || !data?.group?.possibleExamTags.length ? (
                            <TextField inputProps={{ maxLength: 30 }} id="outlined-basic" label="Tag*" variant="outlined" onChange={handleTextTagChange} />
                        ) : (
                            <FormControl style={{ minWidth: 220 }}>
                                <InputLabel id="tag-label">Tag*</InputLabel>
                                <Select labelId="tag-label" id="tag" value={tag} label="Tag*" onChange={handleChange}>
                                    {data?.group?.possibleExamTags.map((tag) => {
                                        return (
                                            <MenuItem key={tag} value={tag}>
                                                {tag}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        ))}
                    {!isForLesson && (
                        <FormControl style={{ minWidth: 220 }}>
                            <InputLabel id="level-label">Level*</InputLabel>
                            <Select labelId="level-label" id="level" value={level} label="Level" onChange={handleLevelChange}>
                                {levels.map((l, i) => {
                                    return (
                                        <MenuItem value={l} key={i}>
                                            {l}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    )}
                    <FormControl style={{ width: 220 }}>
                        <InputLabel id="vulnerability-label">Założenia*</InputLabel>
                        <Select labelId="vulnerability-label" id="vulnerability" value={vulnerability} label="vulnerability" onChange={handleVulnerabilityChange}>
                            {vulnerabilities.map((v, i) => {
                                return (
                                    <MenuItem value={v} key={i}>
                                        {vulnerabilityToString(v)}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    {!isForLesson && (
                        <FormControl style={{ minWidth: 220 }}>
                            <TextField inputProps={{ maxLength: 220 }} maxRows={4} multiline id="outlined-basic" value={comment} label="Opcjonalny komentarz (max 220)" variant="outlined" onChange={handleCommentChange} />
                        </FormControl>
                    )}
                </div>
            </div>
            <div className="backoffice__buttons">
                <IconButton
                    onClick={() => {
                        resetArr();
                        handleLeaveEditClick(null);
                    }}
                    Icon={<RoundClose />}
                    text="odrzuć zmiany"
                />
                <IconButton onClick={handleModalOpen} Icon={<Help />} text="pomoc" />
                <IconButton
                    onClick={() => {
                        handleLeaveEditClick(null);
                        test.id ? saveTest() : createTest();
                    }}
                    disabled={notFilled}
                    Icon={<Save />}
                    text="zapisz"
                />
            </div>
            {notFilled && <p className="backoffice__warning">Uzupełnij wymagane pola</p>}
            <Modal open={helpModalOpen} onClose={handleModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Paper sx={modalStyle}>
                    <img className="backoffice__help" src={window.location.origin + '/images/backoffice-help.png'} alt="Pomoc" />
                    <p>
                        Masz więcej pytań? Napisz do mnie:{' '}
                        <a href="mailTo:magdalena@licytapka.pl" className="link">
                            magdalena@licytapka.pl
                        </a>
                    </p>
                </Paper>
            </Modal>
        </div>
    );
};
