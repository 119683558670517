import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../..//components/Button/Button.tsx';
import { Footer } from '../../../components/Footer/Footer.tsx';
import { ScoreDocument } from '../../../components/Header/header.gen.ts';
import { Header } from '../../../components/Header/Header.tsx';
import { setToken, setUnregisteredUser } from '../../../redux/userSlice.ts';
import { BackofficeStatisticsDocument, BackofficeStatisticsLeaderboardsDocument, BackofficeStatisticsPublicDocument, BackofficeStatisticsPublicLeaderboardsDocument, GetJoiningRequestestsDocument } from '../../Statistics/statistics.gen.ts';
import '../FormPage.scss';
import { LastGroupDocument, MeDocument, useSignInMutation } from './loginPage.gen.ts';
import { loginSchema } from './loginSchema.ts';

const LoginPage = (props: { activated: boolean }) => {
    const { activated } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [signInMutation, { data, loading }] = useSignInMutation({ refetchQueries: [LastGroupDocument, ScoreDocument, MeDocument, ScoreDocument, BackofficeStatisticsDocument, BackofficeStatisticsLeaderboardsDocument, BackofficeStatisticsPublicLeaderboardsDocument, BackofficeStatisticsPublicDocument, GetJoiningRequestestsDocument] });

    const handleSubmit = async (values) => {
        await signInMutation({
            variables: {
                input: values,
            },
        })
            .then((res) => {
                const accessToken = res.data?.signIn.result?.accessToken;
                if (!accessToken) {
                    console.log('No access token required');
                    dispatch(setToken(null));
                } else {
                    dispatch(setToken(accessToken));
                    sessionStorage.setItem('unregisteredUser', JSON.stringify(false));
                    navigate('/licytacja');
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleUnregistered = () => {
        dispatch(setUnregisteredUser());
        navigate('/licytacja');
    };
    return (
        <div className="formPage">
            <Header />
            <div className="formPage__content">
                <p className="formPage__heading">
                    <span className="formPage__heading--licytapka">Licytapka</span> to Twoja partnerka w nauce licytacji brydżowych
                </p>
                {activated && <p className="formPage__heading">Twoje konto zostało aktywowane, teraz możesz się zalogować</p>}
                <Formik
                    initialValues={{
                        login: props.nick || '',
                        password: props.password || '',
                    }}
                    validationSchema={loginSchema}
                    onSubmit={handleSubmit}
                >
                    {(formik) => {
                        const { errors, touched, isValid, dirty } = formik;

                        return (
                            <div className="formPage__formContainer">
                                <div className="formPage__col formPage__col--left">
                                    <h2 className="formPage__title">Zaloguj się</h2>
                                    <Form>
                                        <div className="formPage__formGroup form-row">
                                            <Field maxLength={20} type="login" placeholder="Email lub nick*" name="login" id="login" className={`formPage__formControl ${errors.login && touched.login ? 'input-error' : ''}`} />
                                            <ErrorMessage name="login" component="span" className="error" />
                                        </div>
                                        <div className="formPage__formGroup form-row">
                                            <Field placeholder="Hasło*" type="password" name="password" id="password" className={`formPage__formControl ${errors.password && touched.password ? 'input-error' : ''}`} />
                                            <ErrorMessage name="password" component="span" className="error" />
                                        </div>

                                        <button type="submit" className={`button formPage__button${!(dirty && isValid) ? ' disabled' : ''}`} disabled={!(dirty && isValid)}>
                                            Zaloguj się
                                        </button>
                                    </Form>
                                    {data?.signIn && !data?.signIn?.result && !loading && <p className="formPage__error error">Nieprawidłowy login lub hasło</p>}
                                    <Button
                                        customClass="formPage__link formPage__link--forgetPass"
                                        variant="text"
                                        onClick={() => {
                                            navigate('/password-reset-request');
                                        }}
                                    >
                                        Nie pamiętasz hasła?
                                    </Button>
                                </div>
                                <div className="formPage__col formPage__col--right">
                                    <h2 className="formPage__title">Nie masz konta?</h2>
                                    <Button customClass="formPage__button" variant="ghost-white" onClick={() => navigate('/signup')} text="Załóż konto" />
                                    <p className="formPage__noAccount">
                                        Nie chcesz korzystać z fajnych funkcjonalności jak Statystyki i Grupy?{' '}
                                        <Button
                                            customClass="formPage__link"
                                            variant="text"
                                            onClick={() => {
                                                handleUnregistered();
                                            }}
                                        >
                                            Korzystaj z Licytapki bez zakładania konta.
                                        </Button>
                                    </p>
                                </div>
                            </div>
                        );
                    }}
                </Formik>
            </div>
            <Footer />
        </div>
    );
};
export default LoginPage;
