import React from 'react';
import { Card } from '../Card/Card.tsx';
import './Cards.scss';

export const Cards = (props) => {
    const { cards, small, testName } = props;

    return (
        <>
            <div className={`cards-wrapper${small ? ' small' : ''}`}>
                {testName && <p className="cards__testName">#{testName}</p>}
                <div className={`cards${small ? ' small' : ''}`}>{cards && cards.map((card, index) => <Card card={card} index={index} key={index} />)}</div>
            </div>
        </>
    );
};
