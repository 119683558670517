export const useStatistics = (examResults) => {
    const sumExams = (examResults): { id: string; ratio: string; name: string; value: number; ratioNumber: number }[] => {
        let totalExams = 0;
        let passedExams = 0;

        examResults &&
            examResults.forEach((result) => {
                totalExams += result.totalExamsNumber;
                passedExams += result.passedExamsNumber;
            });

        return [
            { id: 'wrong', ratioNumber: ((totalExams - passedExams) * 100) / totalExams, ratio: (((totalExams - passedExams) * 100) / totalExams || 0).toFixed(), name: 'Niepoprawnie wykonane testy', value: totalExams - passedExams },
            { id: 'passed', ratioNumber: (passedExams * 100) / totalExams, ratio: ((passedExams * 100) / totalExams || 0).toFixed(), name: 'Poprawnie wykonane testy', value: passedExams },
        ];
    };

    const sums = sumExams(examResults);

    const transformedTagData = examResults?.map((r) => {
        return { tag: r.tag, 'Wszystkie testy': r.totalExamsNumber, 'Poprawnie wykonane testy': r.passedExamsNumber };
    });
    const transformedTagDataWithIds = examResults?.map((r) => {
        return { tag: r.tag, total: r.totalExamsNumber, passed: r.passedExamsNumber };
    });
    return { userPassedExamsRatio: sums, transformedTagData: transformedTagData, sumExams, transformedTagDataWithIds };
};
